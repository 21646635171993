<template>
    <table class="table is-bordered is-striped is-hoverable is-responsive">
        <thead>
            <tr>
                <th
                    v-for="(column, index) in columns"
                    :key="column.field"
                    @click="toggleSort(column)"
                    :style="{ width: columnWidths[index] + 'px' }"
                >
                    <div class="resizeable-header">
                        {{ column.title ? $t(column.title) : '&nbsp;' }}
                        <font-awesome-icon v-if="column.sortable" :icon="sortIcon(column)" />
                        <div class="resizer" @mousedown="initResize($event, index)" @touchstart="initResize($event, index)"></div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody v-if="sortedRows && sortedRows.length">
            <tr v-for="row in sortedRows" :key="row.id">
                <td
                    v-for="column in columns"
                    :key="column.field"
                    :data-label="$t(column.title)"
                    :class="{ date: column.fieldType === 'date' }"
                    :style="{ width: columnWidths[columns.findIndex(col => col.field === column.field)] + 'px' }"
                >
                    <template v-if="column.actions">
                        <template v-for="key in Object.keys(column.actions)" :key="key"><button
                                v-if="key === 'edit'"
                                type="button"
                                class="button is-primary icon edit mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.Edit')">
                                <font-awesome-icon icon="pen"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'delete'"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.Delete')">
                                <font-awesome-icon icon="close"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'lock' && row.active"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.Lock')">
                                <font-awesome-icon icon="lock"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'unlock' && !row.active"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.Unlock')">
                                <font-awesome-icon icon="unlock"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'addAdmin' && !row.roles.includes('Administrator')"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.SetAdmin')">
                                <font-awesome-icon icon="user-shield"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'removeAdmin' && row.roles.includes('Administrator')"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.UnsetAdmin')">
                                <font-awesome-icon icon="user-minus"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'addSupervisedRole' && !row.roles.includes('Supervised')"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.SetSupervised')">
                                <font-awesome-icon icon="user-check"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'removeSupervisedRole' && row.roles.includes('Supervised')"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('General.Table.UnsetSupervised')">
                                <font-awesome-icon icon="user-tag"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'reset'"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                @click="column.actions[key](row)"
                                :title="$t('Projects.Reset.Title')">
                                <font-awesome-icon icon="bolt"></font-awesome-icon>
                            </button>
                            <button
                                v-if="key === 'chat'"
                                type="button"
                                class="button is-primary icon delete mr-1"
                                :class="{ 'is-online': !!row.lastActivity}"
                                @click="column.actions[key](row)"
                                :title="$t('Accounts.Chat', {activityName: row.lastActivityEvent, activityDate: formatDate(row.lastActivity, dateFormat, true)})">
                                <font-awesome-icon icon="comments"></font-awesome-icon>
                            </button>
                        </template>
                    </template>
                    <template v-else-if="column.fieldReference">
                        <span v-html="column.fieldReference(row)"></span>
                    </template>
                    <template v-else-if="column.fieldType === 'date'">
                        {{ formatDate(row[column.field], dateFormat, true) }}
                    </template>
                    <template v-else>
                        {{ row[column.field] }}
                    </template>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr class="has-text-centered">
                <td :colspan="columns.length">{{ $t('General.NoData') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { TableRow, TableColumn } from '@/models';
import { formatDate } from '@/utils/formatBytes';
import { computed, PropType, ref, onMounted, onBeforeUnmount } from 'vue';

// Throttle function
const throttle = <T extends (...args: any[]) => void>(func: T, limit: number): T => {
    let inThrottle: boolean;
    return function(this: any, ...args: Parameters<T>) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    } as T;
};

const props = defineProps({
    rows: {
        type: Array as PropType<TableRow[]>,
        required: true,
    },
    columns: {
        type: Array as PropType<TableColumn[]>,
        required: true,
    },
    dateFormat: {
        type: String,
        default: 'dd.MM.yyyy HH:mm',
    },
});

const sortState = ref({
    column: null as null | string,
    direction: 'asc',
});

const toggleSort = (column: TableColumn) => {
    if (!column.sortable) return;

    if (sortState.value.column === column.field) {
        sortState.value.direction = sortState.value.direction === 'asc' ? 'desc' : 'asc';
    } else {
        sortState.value.column = column.field;
        sortState.value.direction = 'asc';
    }
};

const sortIcon = (column: TableColumn) => {
    if (column.field !== sortState.value.column) return ['fas', 'sort'];
    return sortState.value.direction === 'asc' ? ['fas', 'sort-up'] : ['fas', 'sort-down'];
};

const sortedRows = computed(() => {
    if (!sortState.value.column) return props.rows;
    const sortColumn = sortState.value.column;

    const directionMultiplier = sortState.value.direction === 'asc' ? 1 : -1;
    const sorted = [...props.rows].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue < bValue) return -1 * directionMultiplier;
        if (aValue > bValue) return 1 * directionMultiplier;
        return 0;
    });

    return sorted;
});

// Resizable columns logic
const columnWidths = ref(Array(props.columns.length).fill(150)); // Initial widths

const startX = ref(0);
const startWidth = ref(0);
const columnIndex = ref(-1);
const pixelRatio = window.devicePixelRatio || 1;

const throttledResize = throttle((event: MouseEvent | TouchEvent) => {
    const currentX = 'touches' in event ? event.touches[0].clientX : event.clientX;
    const adjustedCurrentX = 'touches' in event ? currentX * pixelRatio : currentX;
    const newWidth = startWidth.value + (adjustedCurrentX - startX.value);
    if (newWidth > 50) {
        columnWidths.value[columnIndex.value] = newWidth;
    }
}, 50);

const initResize = (event: MouseEvent | TouchEvent, index: number) => {
    const startXValue = 'touches' in event ? event.touches[0].clientX : event.clientX;
    startX.value = 'touches' in event ? startXValue * pixelRatio : startXValue;
    startWidth.value = columnWidths.value[index];
    columnIndex.value = index;
    document.addEventListener('mousemove', throttledResize);
    document.addEventListener('touchmove', throttledResize);
    document.addEventListener('mouseup', stopResize);
    document.addEventListener('touchend', stopResize);
};

const stopResize = () => {
    document.removeEventListener('mousemove', throttledResize);
    document.removeEventListener('touchmove', throttledResize);
    document.removeEventListener('mouseup', stopResize);
    document.removeEventListener('touchend', stopResize);
    columnIndex.value = -1;
};

onMounted(() => {
    document.addEventListener('mouseup', stopResize);
    document.addEventListener('touchend', stopResize);
});

onBeforeUnmount(() => {
    document.removeEventListener('mouseup', stopResize);
    document.removeEventListener('touchend', stopResize);
});
</script>

<style scoped lang="scss">
table {
    width: 100%;
    // table-layout: fixed;
}
thead th {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    transition: width 0.2s ease;
}
.resizeable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.resizer {
    z-index: 1;
    width: 26px;
    cursor: col-resize;
    user-select: none;
    position: absolute;
    right: -13px;
    top: 0;
    bottom: 0;
    background: transparent;
}

th,
td {
    word-break: break-word;
    &.date {
        min-width: 175px;
    }
}
.button.icon {
    font-size: 1.3rem;
    padding: 0.5rem;
    margin: 0;
    height: 2.3rem;
    width: 2.3rem;
    &.edit {
        font-size: 1.1rem;
    }
    &.is-online {
        box-shadow: 0 0 0 2px lightgreen;
        border: 3px inset green;
        &:hover {
            background-color: lightgreen;
        }
    }
}

th:first-child {
    width: 150px;
}

@media screen and (max-width: 768px) {
  .table.is-responsive {
    thead {
      display: none;
    }
    
    tr {
      border-bottom: 1px solid #c3c3c3;
      display: block;
      margin-bottom: .625em;
    }

    td {
      border-bottom: 1px solid #c3c3c3;
      display: block;
      font-size: .8em;
      text-align: right;

      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
