import { AccountView } from '@/models/generated/accountView.model';
import { GetterTree, MutationTree } from 'vuex';
import axios from 'axios';

export interface AccountsState {
  accounts: AccountView[]; 
  admins: AccountView[];
}

const mutations: MutationTree<AccountsState> = {
  setAccounts(state: AccountsState, accounts: AccountView[]) {
    state.accounts = accounts;
  },
  setAdmins(state, admins: AccountView[]) { // New mutation for admins
    state.admins = admins;
  },
  updateAccount(state: AccountsState, { id, updatedItem }: { id: string, updatedItem: AccountView }) {
    const index = state.accounts.findIndex(e => e.id === id);
    if (index !== -1) {
      state.accounts.splice(index, 1, updatedItem);
    } else {
      state.accounts.push(updatedItem);
    }
  },
  deleteAccount(state: AccountsState, { id }: { id: string }) {
    const index = state.accounts.findIndex(e => e.id === id);
    if (index !== -1) {
      state.accounts.splice(index, 1);
    }
  },
}

const actions = {
  async getAccounts({ commit }: { commit: any }) {
    const response = await axios.get<AccountView[]>('/api/account');
    if (response.status >= 200 && response.status <= 299) {
      commit('setAccounts', response.data);
    }
    commit('setAccounts', response.data);
  },
  async getAdmins({ commit }: { commit: any }) {
    try {
      const response = await axios.get<AccountView[]>('/api/account/admins');
      if (response.status >= 200 && response.status <= 299) {
        commit('setAdmins', response.data);
      }
    } catch (error) {
      console.error('Failed to fetch admins:', error);
    }
  },
  async getAccount({ commit }: { commit: any }, id: string) {
    const response = await axios.get<AccountView[]>(`/api/account/${id}`);
    commit('updateAccount', { id, updatedItem: response.data });
  },
  async updateAccountLocked({ commit }: { commit: any }, { id, account }: { id: string, account: AccountView }) {
    const response = await axios.put(`/api/account/${id}/lock`, { active: account.active });
    if (response.status >= 200 && response.status <= 299) {
      commit('updateAccount', { id: account.id, updatedItem: account });
    }
  },
  async updateAccountRoles({ commit }: { commit: any }, { id, account }: { id: string, account: AccountView }) {
    const response = await axios.put(`/api/account/${id}/roles`, { roles: account.roles });
    if (response.status >= 200 && response.status <= 299) {
      commit('updateAccount', { id: account.id, updatedItem: account });
    }
  },
  async deleteAccount({ commit }: { commit: any }, id: string ) {
    const response = await axios.delete(`/api/account/${id}`);
    if (response.status >= 200 && response.status <= 299) {
      commit('deleteAccount', { id });
    }
  },
}

const getters: GetterTree<AccountsState, any> = {
  accountById: (state: AccountsState) => (id: string) => {
    return (state.accounts && state.accounts.find(e => e?.id === id)) || null;
  }
};

export const accountsStore = ({
  namespaced: true,
  state: () => ({
    accounts: [] as AccountView[],
    admins: [] as AccountView[],
  }),
  mutations,
  actions,
  getters,
});
