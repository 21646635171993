<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <div style="max-width:400px;margin: auto;">
                        <div class="content has-text-centered">
                            <div class="box">
                                <div style="height: 320px;position:relative;" v-show="!hasImage"
                                    class="level-item has-text-centered">
                                    <loading-spinner-dotted></loading-spinner-dotted>
                                    <h3 v-show="project.status !== 'FILE_PROCESSING_FINISHED'"
                                        style="position:absolute; left: calc(50% - 20px);" class="title is-5"
                                        v-html="getCurrentProjectStatePercentage(project.status)"></h3>
                                </div>
                                <img v-show="hasImage" @load="hasImage = true" @error="hasImage = false"
                                    style="height: 320px;" v-loadimage="{ 'projectId': route.params.id }"
                                    alt="Project preview" />
                                <h2 class="title is-4">{{ project.name || '&nbsp;' }}</h2>
                                <div v-if="!project.status?.includes('_FAILED') && hasImage && project.status !== 'FILE_PROCESSING_FINISHED'"
                                    class="loading-info is-flex is-justify-content-space-around"
                                    style="position:relative;height: 30px;">
                                    <div class="spinner-wrapper">
                                        <loading-spinner-dotted></loading-spinner-dotted>
                                    </div>
                                    <span class="loading-percentage"
                                        v-html="getCurrentProjectStatePercentage(project.status)"></span>
                                </div>
                                <div v-if="project.tries > 9 && project.status?.includes('_FAILED')">
                                    {{ $t('ProjectDetail.Error') }}
                                </div>
                                <div v-else-if="project.status == null || project.status == ''">
                                    {{ $t('ProjectDetail.Processing') }}
                                </div>
                                <div v-else-if="project.status !== 'FILE_PROCESSING_FINISHED'">
                                    {{ $t('ProjectDetail.Processing') }}
                                </div>
                                <p v-if="store.state.auth.isAdmin">{{ $t('ProjectDetail.Status') }}: {{ project.status
                                    }}
                                    <br />
                                    Tries: {{ project.tries }}
                                    <br />
                                    Size: {{ project.fileSize }} MB ({{ project.fileName }})
                                    <br />
                                    #Files: {{ project.projectFiles?.length ?? 0 }} User: {{ project.createdByUser }}
                                </p>
                                <p class="">{{ $t('General.CreatedOn') }}
                                    {{ formatDate(project.dateCreated, userDateFormat + ' HH:mm', true) }}</p>
                                <p v-if="store.state.auth.isAdmin" class="">{{ $t('General.LastUpdate') }}:
                                    {{ formatDate(project.dateModified, userDateFormat + ' HH:mm', true) }}</p>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div class="column is-half">
                    <h3 class="subtitle is-3 has-text-centered">
                        {{ $t('ProjectDetail.Title') }}
                    </h3>

                    <ul class="grid-list mb-5" style="margin:auto;">
                        <li class="is-clickable subtitle is-5" @click="downloadPointcloud(project.id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>{{ $t('General.Pointcloud') }}</span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5">
                            <span :title="file.id" v-for="(file, index) in project.projectFiles" :key="file.id"
                                @click="downloadE57(file.id)">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                                <span>{{ index }}.e57</span>
                            </span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5" @click="download3DM(project.id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.3dm</span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5">
                            <span :title="file.id" v-for="(file, index) in project.projectFiles" :key="file.id"
                                @click="downloadPLY(file.id)">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                                <span>{{ index }}.ply</span>
                            </span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5" @click="downloadMergedDXF(project.id)">
                            <!-- shopping-cart -->
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>merged.dxf</span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5">
                            <span :title="file.id" v-for="(file, index) in project.projectFiles" :key="file.id"
                                @click="downloadDXF(project.id, file.id)">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                                <span>{{ index }}.dxf</span>
                            </span>
                            <!-- <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.e57</span> -->
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5"
                            @click="downloadIFC(project.id)">
                            <!-- <font-awesome-icon icon="download" class="mr-2" /> -->
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.ifc</span>
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5"
                            @click="downloadDWG(project.id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.dwg</span>
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5"
                            @click="downloadRVT(project.id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.rvt</span>
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5"
                            @click="downloadPLN(project.id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.pln</span>
                        </li>
                        <li v-if="isAdmin && (project.status === 'C2M_COMPARISON_FINISHED' || project.status === 'XKT_CONVERSION_STARTED' || project.status === 'XKT_CONVERSION_FINISHED' || project.status === 'FILE_PROCESSING_FINISHED')"
                            class="is-clickable subtitle is-5" @click="downloadC2ME57(project.id, project.projectFiles[0].id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>c2m.e57</span>
                        </li>
                        <li v-if="isAdmin && (project.status === 'XKT_CONVERSION_FINISHED' || project.status === 'FILE_PROCESSING_FINISHED')"
                            class="is-clickable subtitle is-5" @click="downloadLAZ(project.id, project.projectFiles[0].id, '01')">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.laz</span>
                        </li>
                        <li v-if="isAdmin && (project.status === 'XKT_CONVERSION_FINISHED' || project.status === 'FILE_PROCESSING_FINISHED')"
                            class="is-clickable subtitle is-5" @click="downloadXKTIFC(project.id)">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>ifc.xkt</span>
                        </li>
                        <li v-if="isAdmin && (project.status === 'XKT_CONVERSION_FINISHED' || project.status === 'FILE_PROCESSING_FINISHED')"
                            class="is-clickable subtitle is-5" @click="downloadXKTLAZ(project.id, project.projectFiles[0].id, '01')">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>laz.xkt</span>
                        </li>
                        <li v-if="project.status === 'BCF_CREATION_FINISHED' || project.status === 'FILE_PROCESSING_FINISHED'"
                            class="is-clickable subtitle is-5" @click="downloadBCF(project.id, '01')">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.bcf</span>
                        </li>
                    </ul>
                    <div v-if="!isInviteUsersOpen" class="field material-input has-text-centered mt-5">
                        <div class="control">
                            <button class="button is-primary" @click="toggleInviteUsers">
                                {{ $t('ProjectDetail.Share') }}</button>
                        </div>
                    </div>
                    <div v-if="isInviteUsersOpen" class="field material-input mt-5 box">
                        <form v-form-validate="{ submitMethod: sendInviteUsers, locale: locale }">
                            <div class="control">
                                <input v-model="inviteUsers" class="input" type="text" placeholder="" required>
                                <label class="label">{{ $t('ProjectDetail.InviteUsers.Title') }}</label>
                            </div>
                            <div class="is-flex is-fullwidth is-justify-content-space-evenly mt-4">
                                <button type="submit" class="button is-primary">{{ $t('ProjectDetail.Share') }}</button>
                                <button type="button" class="button is-danger" @click="toggleInviteUsers">
                                    {{ $t('General.Abort') }}</button>
                            </div>
                        </form>
                    </div>
                    <div class="has-text-centered"><button class="button is-primary" @click="goToProjectBim()">Zum BIM-Viewer</button></div>
                </div>
            </div>
        </div>
        <div v-if="isAdmin" class="container">
            <div class="columns">
                <div class="column is-half">
                    <div>
                        <div class="field material-select" style="width: 400px;display: inline-block;">
                            <select class="select" v-model="updateStatus">
                                <option value="">- Bitte auswählen -</option>
                                <option v-for="status in uploadStatuses" :key="status" :value="status">
                                    {{ status }}
                                </option>
                            </select>
                            <label class="label">{{ $t('ProjectDetail.ChooseStatus') }}</label>
                            <div class="icon">
                                <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                            </div>
                        </div>
                        <button class="button is-primary" style="float:right;" @click="updateProjectStatus">Update Status</button>
                    </div>
                    
                    <div style="display: flex; justify-content: space-evenly;">
                        <button v-if="!project.isStopped" class="button is-danger" @click="setProjectStopped(true)">
                            {{ $t('ProjectDetail.Stop') }}</button>
                            
                        <button v-if="project.isStopped" class="button is-success" @click="setProjectStopped(false)">
                            {{ $t('ProjectDetail.Start') }}</button>
                    </div>
                </div>

                <div class="column is-half">
                    <div class="box box-compact drop-zone-wrapper">
                        <div class="drop-zone card" @click="triggerFileInput" @dragover.prevent="onDragOver"
                            @drop.prevent="onDrop">
                            <div class="card-content has-text-centered">
                                <h1 class="title is-5">{{ $t('ProjectDetail.UploadFiles') }}</h1>
                                <div class="upload-icon">
                                    <i class="nyne-icon nyne-icon-upload"></i>
                                </div>
                                <input type="file" ref="fileInput" @change="onFileChange" class="is-hidden" />
                            </div>
                            <p class="has-text-centered" v-if="uploadProgress > 0">
                                {{ $t('Upload.Progress') }}: {{ uploadProgress }}%
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isAdmin" class="container">
                <div class="columns">
                    <div class="column is-full">
                        <h3 class="subtitle is-3 has-text-centered">
                            {{ $t('ProjectDetail.History.Title') }}
                        </h3>
                        <SortableTable :columns="columns" :rows="projectHistory"
                            :date-format="userDateFormat + ' HH:mm:ss'"></SortableTable>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-full">
                        <h3 class="subtitle is-3 has-text-centered">
                            {{ $t('Pointcloud Poses') }}
                        </h3>
                        <SortableTable :columns="pcPoseColumns" :rows="pcPoses"
                            :date-format="userDateFormat + ' HH:mm:ss'"></SortableTable>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '../store';
import { PointcloudPose, ProjectStatusView, ProjectView } from '@/models/project.model';
import { formatDate } from '@/utils/formatBytes';
import { getCurrentProjectStatePercentage } from '@/utils/helper';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerDotted from './LoadingSpinnerDotted.vue';
import { TableColumn } from '@/models';
import SortableTable from './SortableTable.vue';
import { useDownloadHelper } from '@/utils/helper';


const { t, locale } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const project: ProjectView = reactive<ProjectView>({
    id: '',
    tries: 0,
    projectFiles: [],
    isStopped: false,
});
const isAdmin = computed(() => store.state.auth.isAdmin);
const isInviteUsersOpen = ref<boolean>(false);
const inviteUsers = ref<string>('');
const hasImage = ref(false);
const projectHistory = ref<ProjectStatusView[]>([]);
const pcPoses = ref<PointcloudPose[]>([]);
const updateStatus = ref<string>('');
const fileInput = ref<any>(null);
const uploadProgress = ref<number>(0);

const userDateFormat = computed(() => store.state.auth.userData?.dateFormat ?? 'dd.MM.yyyy');

const uploadStatuses = [
  "START",
  "FILE_UPLOAD_FAILED",
  "FILE_UPLOADED",
  "FILE_PREPROCESSING_QUEUED",
  "FILE_PREPROCESSING_STARTED",
  "FILE_PREPROCESSING_REQUEST_FAILED",
  "WRONG_FILE_TYPE",
  "FILE_PREPROCESSING_FAILED",
  "E57_EXTRACTED",
  "FILE_PREPROCESSING_FINISHED",
  "FILE_PROCESSING_STARTED",
  "FILE_PROCESSING_FAILED",
  "WIREFRAME_CONVERSION_FINISHED",
  "DXF_MERGE_STARTED",
  "DXF_MERGE_FAILED",
  "DXF_MERGE_FINISHED",
  "BIM_CONVERSION_QUEUED",
  "BIM_CONVERSION_REDO",
  "BIM_CONVERSION_STARTED",
  "BIM_CONVERSION_FAILED",
  "BIM_CONVERSION_FINISHED",
  "FILE_VALIDATION_STARTED",
  "FILE_VALIDATION_FAILED",
  "FILE_VALIDATION_FINISHED",
  "BCF_CREATION_STARTED",
  "BCF_CREATION_FAILED",
  "BCF_CREATION_FINISHED",
  "XKT_PROCESSING_STARTED",
  "XKT_PROCESSING_FAILED",
  "XKT_PROCESSING_FINISHED",
  "FILE_PROCESSING_FINISHED",
];

const { downloadPointcloud, downloadE57, download3DM, downloadPLY, downloadDXF, downloadMergedDXF, downloadIFC, downloadDWG, downloadRVT, downloadPLN, downloadC2ME57, downloadLAZ, downloadXKTIFC, downloadXKTLAZ, downloadBCF } = useDownloadHelper(store);

store.commit('app/setLayout', 'default');

const columns: TableColumn[] = [
    {
        field: 'id',
        title: '',
        sortable: false,
        actions: {
            reset: async (projectStatus: any) => {
                if (store.state.auth.isAdmin) {
                    const result = await store.dispatch('project/resetProjectFrom', { id: project.id, projectStatusId: projectStatus.id });
                    project.status = result.status;
                    store.commit('app/showToast', {
                        message: t('Projects.Reset.Success'),
                        type: 'success',
                    });

                    projectHistory.value = await store.dispatch('project/getProjectHistory', project.id);
                }
            },
        },
    },
    {
        field: 'dateCreated',
        fieldType: 'date',
        title: 'ProjectDetail.History.Date',
        sortable: true,
    },
    { field: 'fileId', title: 'ProjectDetail.History.FileId', sortable: true },
    { field: 'status', title: 'ProjectDetail.History.Status', sortable: true },
    { field: 'tries', title: 'ProjectDetail.History.Tries', sortable: true },
    { field: 'serverVersion', title: 'ProjectDetail.History.ServerVersion', sortable: true },
    { field: 'processingServer', title: 'ProjectDetail.History.ProcessingServer', sortable: true },
];

const pcPoseColumns: TableColumn[] = [
    { field: 'scanIndex', title: 'ProjectDetail.Pointcloud.ScanIndex', sortable: true },
    { field: 'transX', title: 'Translation X', sortable: true },
    { field: 'transY', title: 'Translation Y', sortable: true },
    { field: 'transZ', title: 'Translation Z', sortable: true },
    { field: 'rotX', title: 'Rotation X', sortable: true },
    { field: 'rotY', title: 'Rotation Y', sortable: true },
    { field: 'rotZ', title: 'Rotation Z', sortable: true },
    { field: 'rotW', title: 'Rotation W', sortable: true },
    { field: 'scaleX', title: 'Scale X', sortable: true },
    { field: 'scaleY', title: 'Scale Y', sortable: true },
    { field: 'scaleZ', title: 'Scale Z', sortable: true },
    { field: 'comment', title: 'Comment', sortable: true },
];

const toggleInviteUsers = async () => {
    isInviteUsersOpen.value = !isInviteUsersOpen.value;
}
const sendInviteUsers = async () => {
    if (!inviteUsers.value || inviteUsers.value.trim() === '') return;
    await store.dispatch('project/inviteUsers', { id: route.params.id, emails: inviteUsers.value });
    inviteUsers.value = '';
    isInviteUsersOpen.value = !isInviteUsersOpen.value;
    store.commit('app/showToast', {
        message: t('ProjectDetail.InviteUsers.Success'),
        type: 'success',
    });
}

const setProjectStopped = (value: boolean) => {
    if (value) {
        store.dispatch('project/stopProject', route.params.id);
        store.commit('app/showToast', {
            message: t('ProjectDetail.StopSuccess'),
            type: 'success',
        });
    } else {
        store.dispatch('project/startProject', route.params.id); 
        store.commit('app/showToast', {
            message: t('ProjectDetail.StartSuccess'),
            type: 'success',
        });
    }

    project.isStopped = !project.isStopped;
}

const goToProjectBim = () => {
    router.push({ name: 'ProjectBim', params: { id: route.params.id } });
}

const updateProjectStatus = async () => {
    if (!updateStatus.value) {
        return;
    }
    await store.dispatch('project/updateStatus', { id: route.params.id, status: updateStatus.value });
    projectHistory.value = await store.dispatch('project/getProjectHistory', route.params.id);
    await store.dispatch('project/getProject', route.params.id);
    const item = store.getters['project/projectById'](route.params.id);
    Object.assign(project, item);
    updateStatus.value = '';
}

// Trigger file input when the drop zone is clicked
const triggerFileInput = () => {
    fileInput.value?.click();
};

// Handle drag over (optional visual feedback can be added)
const onDragOver = () => {
    // No specific functionality, but could add styling for drag over
};

// Handle file drop
const onDrop = (event: DragEvent) => {
    const files = event.dataTransfer?.files;
    if (!files || files.length === 0) {
        return;
    }

    fileInput.value = files[0];
    processFile();
};

const onFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
        fileInput.value = target.files[0];
        processFile();
    }
};

const processFile = async () => {
    const file = fileInput.value;
    store.state.app.disableLoadingSpinner = true;
    const endpoint = '/admin/files';
    await store.dispatch('upload/startUpload', { file, endpoint, id: route.params.id });
};

watch(
    () => route.params.id as string,
    async id => {
        if (id) {
            let foundItem = store.getters['project/projectById'](id);
            if (foundItem == null) {
                await store.dispatch('project/getProject', id);
                foundItem = store.getters['project/projectById'](id);
            }

            if (foundItem == null) {
                // still no data present, id does not exist
            } else {
                await store.dispatch('project/getProject', id);
                foundItem = store.getters['project/projectById'](id);
            }

            if (foundItem) {
                // console.log("found item", foundItem);
                Object.assign(project, foundItem);
            } else {
                // redirect zu 404, not found
            }

            if (store.state.auth.isAdmin) {
                projectHistory.value = await store.dispatch('project/getProjectHistory', id);
                pcPoses.value = await store.dispatch('project/getPointcloudPoses', id);
            }
        }
    },
    { immediate: true },
);
</script>
<style scoped lang="scss">
// .grid-list {
//     // text-align: center;
//     // width: 250px;
// }

.grid-list li {
    display: grid;
    grid-template-columns: auto auto 1fr;
    /* Spacing, icon, and text */
    align-items: center;
    gap: 8px;
    /* space between icon and text */
    cursor: pointer;
}

.grid-list .nyne-icon {
    margin-left: -8px;

    &::before {
        font-size: 1.7rem;
    }
}

.grid-list li::before {
    content: '';

    /* Necessary for the pseudo-element to display */
    @media screen and (min-width: 768px) {
        width: 125px;
    }

    @media screen and (min-width: 850px) {
        width: 155px;
    }

    @media screen and (min-width: 1024px) {
        width: 190px;
    }

    @media screen and (min-width: 1216px) {
        width: 230px;
    }

    @media screen and (min-width: 1408px) {
        width: 280px;
    }

    /* Adjust this value for desired spacing */
    display: inline-block;
    /* Or block, depending on your layout needs */
}

// .grid-list li .icon {
//     /* Adjust icon styles if necessary */
// }


// .clickable-item:hover {
//     /* Style for hover state, if needed */
// }

.drop-zone {
    border: 2px dashed #ccc;
    border-radius: 10px;
    transition: border-color 0.25s ease-in-out;
    padding: 0;
    text-align: center;
    cursor: pointer;
}

.drop-zone:hover {
    border-color: #3273dc;
}

.upload-icon .nyne-icon {
    font-size: 3rem;
}

.box.box-compact {
    height: 150px;
    width: 150px;
}
.box.box-compact > *:first-child {
    padding-top: 20px;
}
</style>
