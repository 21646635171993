import { Store } from 'vuex';

export const getCurrentProjectStatePercentage = (status?: string) => {
    if (!status) {
        return "1%";
    }
    if (status === "START") {
        return "10%";
    } else if (status === "FILE_UPLOADED") {
        return "15%";
    } else if (status === "FILE_PREPROCESSING_STARTED") {
        return "20%";
    } else if (status === "E57_EXTRACTED") {
        return "26%";
    } else if (status === "FILE_PREPROCESSING_FINISHED") {
        return "33%";
    } else if (status === "KPCONV_QUEUED") {
        return "34%";
    } else if (status === "PLY_CONVERSION_STARTED") {
        return "37%";
    } else if (status === "PLY_CONVERSION_FINISHED") {
        return "45%";
    } else if (status === "PLY_ANNOTATION_STARTED") {
        return "46%";
    } else if (status === "PLY_ANNOTATION_FINISHED") {
        return "49%";
    } else if (status === "DXF_CONVERSION_QUEUED") {
        return "50%";
    } else if (status === "DXF_CONVERSION_STARTED") {
        return "52%";
    } else if (status === "DXF_CONVERSION_FINISHED") {
        return "60%";
    } else if (status === "DXF_MERGE_STARTED") {
        return "61%";
    } else if (status === "DXF_MERGE_FINISHED") {
        return "65%";
    } else if (status === "BIM_CONVERSION_QUEUED") {
        return "66%";
    } else if (status === "BIM_CONVERSION_STARTED") {
        return "67%";
    } else if (status === "BIM_CONVERSION_FINISHED") {
        return "73%";
    } else if (status === "C2M_COMPARISON_STARTED") {
        return "79%";
    } else if (status === "C2M_COMPARISON_FINISHED") {
        return "80%";
    } else if (status === "XKT_CONVERSION_STARTED") {
        return "87%";
    } else if (status === "XKT_CONVERSION_FINISHED" || status === "FILE_PROCESSING_FINISHED" ) {
        return "99%";
    } else {
        return "";
    }
}


export function useDownloadHelper(store: Store<any>) {
    const generateDownloadToken = async (id: string, type: string, fileId?: string, suffix?: string) => {
        if (!id) return;

        await store.dispatch('project/generateDownloadToken', {
            id,
            type,
            ...(fileId ? { fileId } : {}),
            ...(suffix ? { suffix } : {})
        });
    };

    return {
        downloadE57: (id: string, fileId?: string) => generateDownloadToken(id, 'e57', fileId),
        download3DM: (id: string) => generateDownloadToken(id, '3dm'),
        downloadPLY: (id: string, fileId?: string) => generateDownloadToken(id, 'ply', fileId),
        downloadDXF: (id: string, fileId?: string) => generateDownloadToken(id, 'dxf', fileId),
        downloadMergedDXF: (id: string) => generateDownloadToken(id, 'dxf'),
        downloadRVT: (id: string) => generateDownloadToken(id, 'rvt'),
        downloadIFC: (id: string) => generateDownloadToken(id, 'ifc'),
        downloadDWG: (id: string) => generateDownloadToken(id, 'dwg'),
        downloadPLN: (id: string) => generateDownloadToken(id, 'pln'),
        downloadC2ME57: (id: string, fileId: string) => generateDownloadToken(id, 'c2m_e57', fileId),
        downloadLAZ: (id: string, fileId: string, suffix: string = '01') => generateDownloadToken(id, 'laz', fileId, suffix),
        downloadXKTIFC: (id: string) => generateDownloadToken(id, 'xkt_ifc'),
        downloadXKTLAZ: (id: string, fileId: string, suffix: string = '01') => generateDownloadToken(id, 'xkt_laz', fileId, suffix),
        downloadBCF: (id: string, suffix: string = '01') => generateDownloadToken(id, 'bcf', undefined, suffix),
        downloadBCFJson: (id: string, suffix: string = '01') => generateDownloadToken(id, 'bcfjson', undefined, suffix),
        downloadPointcloud: (id: string) => generateDownloadToken(id, 'pointcloud')
    };
}
