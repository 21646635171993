import { ActionTree, GetterTree, MutationTree } from 'vuex';
import axios from 'axios';

export interface SharedProjectView {
    id: string;
    name: string;
    sharedPersons: PersonView[];
}

export interface PersonView {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    profileImage?: string;
    sharedProjects: SharedProjectView[];
}

export interface TeamState {
    persons: PersonView[];
    filteredPersons: PersonView[];
    selectedPerson: PersonView | null;
}

const state = (): TeamState => ({
    persons: [],
    filteredPersons: [],
    selectedPerson: null,
});

const mutations: MutationTree<TeamState> = {
    setPersons(state: TeamState, persons: PersonView[]) {
        state.persons = persons;
        state.filteredPersons = persons;
    },
    setFilteredPersons(state: TeamState, filteredPersons: PersonView[]) {
        state.filteredPersons = filteredPersons;
    },
    setSelectedPerson(state: TeamState, person: PersonView) {
        state.selectedPerson = person;
    },
    removePersonAccess(state: TeamState, { personId }: { personId: string }) {
        const index = state.persons.findIndex(person => person.id === personId);
        if (index !== -1) {
            state.persons.splice(index, 1);
        }
    },
    removePersonFromAllProjects(state: TeamState, { personId }: { personId: string }) {
        state.persons = state.persons.filter(person => person.id !== personId);
        state.filteredPersons = state.persons;
    },
};

const actions: ActionTree<TeamState, any> = {
    async getPersons({ commit }) {
        const response = await axios.get<PersonView[]>('/api/team');
        if (response.status === 200) {
            commit('setPersons', response.data);
        }
    },
    async getPerson({ commit }, personId: string) {
        try {
            const response = await axios.get<PersonView>(`/api/team/${personId}`);
            if (response.status === 200) {
                commit('setSelectedPerson', response.data);
            }
        }catch(e){
            commit('setSelectedPerson', null);
        }
    },
    async deleteAccess({ commit }, { personId, projectId }: { personId: string; projectId: string }) {
        try {
            const response = await axios.delete(`/api/team/${personId}/project/${projectId}/access`);
            if (response.status >= 200 && response.status <= 299) {
                commit('removePersonAccess', { personId });
            }
        } catch(e) {
            commit('removePersonAccess', { personId });
        }
    },
    async removePersonFromAllProjects({ commit }, personId: string) {
        const response = await axios.delete(`/api/team/${personId}/remove-from-all-projects`);
        if (response.status >= 200 && response.status <= 299) {
            commit('removePersonFromAllProjects', { personId });
        }
    },
    async inviteUserToAllProjects(_, emails: string) {
        const response = await axios.post(`/api/team/invite-to-all-projects`, { emails });
        if (response.status >= 200 && response.status <= 299) {
            console.log('Invitation sent to all projects.');
        } else if (response.data?.invalidEmails) {
            console.log('Some emails were invalid:', response.data.invalidEmails);
        }
    },
    filterPersonsByEmail({ commit, state }, searchTerm: string) {
        if (!searchTerm) {
            return commit('setFilteredPersons', state.persons);
        }
        const filtered = state.persons.filter(person =>
          person.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        commit('setFilteredPersons', filtered);
      },
};

const getters: GetterTree<TeamState, any> = {
    filteredPersons: (state: TeamState) => state.filteredPersons,
    personById: (state: TeamState) => (id: string) => {
        return state.persons.find(person => person.id === id) || null;
    },
    selectedPerson: (state: TeamState) => {
        return state.selectedPerson;
    },
};

export const teamStore = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
