import { createApp } from "vue";
import App from "./App.vue";
import { setupI18n } from './i18n';
import router from "./router";
import { validationPlugin } from './directives/validationDirective';
import { formValidationPlugin } from './directives/formValidationDirective';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAdd, faAngleDown, faAngleUp, faBars, faBolt, faBuilding, faCar, faCarSide, faCheck, faCheckDouble, faCheckSquare, faChevronLeft, faChevronRight, faChevronUp, faChevronDown, faClockRotateLeft, faCloud, faCloudUpload, faClone, faComments, faCube, faDownload, faEye, faEyeSlash, faEnvelope, faFileExcel, faHouse, faGear, faInfoCircle, faLanguage, faLock, faLocationArrow, faMinus, faPen, faRepeat, faRuler, faSave, faUserPlus, faUserCheck, faUserTag, faShoppingCart, faSignIn, faSignOut, faSort, faSortUp, faSortDown, faTrash, faTimes, faTriangleExclamation, faUnlock, faUpload, faUser, faUserMinus, faUsers, faUserShield, faQuestionCircle, faTable, } from "@fortawesome/free-solid-svg-icons";
import {  } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { store, key } from './store'
import axios from 'axios';

import "@/assets/css/global.scss"
import { loadImagePlugin } from "./directives/loadImageDirective";

// import "bulma/css/bulma.css";
library.add(faAdd, faAngleDown, faAngleUp, faBars, faBolt, faBuilding, faCar, faCarSide, faCheck, faCheckDouble, faCheckSquare, faChevronLeft, faChevronRight, faChevronUp, faChevronDown, faClockRotateLeft, faCloud, faCloudUpload, faClone, faComments, faCube, faDownload, faEye, faEyeSlash, faEnvelope, faFileExcel, faHouse, faGear, faInfoCircle, faLanguage, faLock, faLocationArrow, faMinus, faPen, faRepeat, faRuler, faSave, faUserPlus, faUserCheck, faUserTag, faShoppingCart, faSignIn, faSort, faSortUp, faSortDown, faSignOut, faTable, faTrash, faTimes, faTriangleExclamation, faUnlock, faUpload, faUser,  faUserMinus, faUsers, faUserShield, faQuestionCircle );

axios.defaults.baseURL = process.env.VUE_APP_BACKEND ?? "https://localhost:23014";
const baseUrl = window.location.origin;
axios.defaults.baseURL = (baseUrl.includes(":8080") ? baseUrl.replace(':8080', ':5212') : axios.defaults.baseURL);

axios.interceptors.request.use(config => {
    store.commit('app/showLoadingSpinner', true);
    const token = store.state.auth.token;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    // Handle request error
    console.error('Leider ist ein Fehler aufgetreten:', error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    store.commit('app/hideLoadingSpinner');
    return response;
}, error => {
    // Handle request error
    store.commit('app/hideLoadingSpinner');
    store.commit('app/showToast', { message: error?.response?.data?.errors?.join(",") ?? "Leider ist ein Fehler aufgetreten: " + error, type: "danger" });
    console.error('Leider ist ein Fehler aufgetreten:', error);
    return Promise.reject(error);
});

const i18n = await setupI18n();
const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(i18n);
app.config.globalProperties.$t = i18n.global.t;
app.use(validationPlugin);
app.use(formValidationPlugin);
app.use(loadImagePlugin);
app.use(router);
app.use(store, key);

app.mount("#app");
