<template>
    <section class="section p-0" style="margin-top: -70px;margin-bottom: -40px;">
        <div class="xeokit-viewer-wrapper">
            <canvas id="xeokit_canvas" class="xeokit-canvas"></canvas>
            <canvas id="navcube" class="nav-cube"></canvas>
            <!-- <div id="minimap" class="minimap"></div> -->

            <div v-if="isAdmin" class="sidebar-right">
                <h2 class="is-size-5 title mt-4" style="text-align: center;">PARAMETERS</h2>
                <div class="issues mx-3" v-if="bcfJsonData">
                    Number of Issues: {{ bcfJsonData.length }}
                    <div v-for="(issue, index) in bcfJsonData" :key="issue.id">
                        <label @click="setViewpoint(index)" :title="issue.title">{{index + ". " + issue.labels[0] }}</label>
                        <!-- <p>{{ issue.description }}</p> -->
                        <div v-for="comment in issue.comments" :key="comment.id">
                            <p>{{ comment.comment }}</p>
                        </div>
                    </div>
                </div>
                <div class="summary">
                    <p class="mt-2" v-if="squareMeters && squareMeters.length > 0">
                        {{ squareMeters }}
                    </p>
                    <p class="mt-3" style="line-height: 10px;">
                        {{ project.fileSize }}MB UPLOADED</p>
                    <p>
                        {{ formatDate(project.dateCreated, userDateFormat + ', HH:mm', true) }}
                    </p>
                </div>
            </div>

            <div class="ruler-buttons">
                <ul class="menu-list">
                    <li>
                        <router-link :to="`/projectshare/${projectId}`" class="btn button is-primary menu-item">
                            <div class="icon-wrapper">
                                <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Group_add_light.svg" />
                            </div>
                        </router-link>
                    </li>
                    <li class="dropdown is-hoverable is-up">
                        <div class="dropdown-trigger">
                            <a class="btn button is-primary menu-item" aria-haspopup="true"
                                aria-controls="dropdown-menu">
                                <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                    <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a @click="downloadPointcloud(project.id)" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">Pointcloud</span>
                                </a>
                                <a @click="downloadIFC(project.id)" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">IFC</span>
                                </a>
                                <a @click="downloadDWG(project.id)" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">DWG</span>
                                </a>
                                <a @click="downloadPLN(project.id)" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">PLN</span>
                                </a>
                                <a @click="downloadRVT(project.id)" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">RVT</span>
                                </a>
                                <a v-if="isAdmin" @click="downloadC2ME57(project.id, project.projectFiles[0].id)"
                                    class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">Validated e57</span>
                                </a>
                                <a v-if="isAdmin"
                                    @click="downloadLAZ(project.id, project.projectFiles[0].id, currentTolerance)"
                                    class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">LAZ</span>
                                </a>
                                <a v-if="isAdmin" @click="downloadXKTIFC(project.id)" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">XKT IFC</span>
                                </a>
                                <a v-if="isAdmin"
                                    @click="downloadXKTLAZ(project.id, project.projectFiles[0].id, currentTolerance)"
                                    class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">XKT LAZ</span>
                                </a>
                                <a v-if="isAdmin" @click="downloadBCF(project.id, currentTolerance)"
                                    class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-top: 3px; margin-left: -2px">
                                        <img class="nyne-icon" src="@/assets/media/Import_light.svg" alt="Download" />
                                    </div>
                                    <span class="column text p-0 pl-4">BCF</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <button @click="fitView" class="btn button is-primary menu-item">
                            <div class="icon-wrapper" style="margin-left: -9px; margin-top: 13px">
                                <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Send_light.svg" />
                            </div>
                        </button>
                    </li>
                    
                    <li class="dropdown is-hoverable is-up">
                        <div class="dropdown-trigger">
                            <a class="btn button menu-item"
                                :class="{ 'is-primary': !isMeasuring, 'is-selected': isMeasuring }">
                                <div class="icon-wrapper" style="margin-left: -2px;margin-top: 1px;" >
                                    <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Ruler.svg" />
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a @click="activateDistanceMeasurement" class="dropdown-item"  :class="{ 'is-primary': !isMeasuring, 'is-selected': isMeasuring }">
                                    <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                        <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Ruler.svg" />
                                    </div>
                                    <span class="column text p-0 pl-4">Distance</span>
                                </a>
                                <a @click="activateAngleMeasurement" class="dropdown-item" :class="{ 'is-primary': !isMeasuringAngle, 'is-selected': isMeasuringAngle }">
                                    <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                        <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Ruler.svg" />
                                    </div>
                                    <span class="column text p-0 pl-4">Angle</span>
                                </a>
                                <a v-if="isMeasuring || isMeasuringAngle" @click="resetMeasurements" class="dropdown-item">
                                    <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                        <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Ruler.svg" />
                                    </div>
                                    <span class="column text p-0 pl-4">Reset Measurements</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <button @click="toggle2D3D" class="btn button menu-item is-primary">
                            <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/3d_box_duotone.svg" />
                            </div>
                        </button>
                    </li>

                    <li class="dropdown is-hoverable is-up">
                        <div class="dropdown-trigger">
                            <a class="btn button is-primary menu-item" aria-haspopup="true" aria-controls="dropdown-menu">
                                <div class="icon-wrapper" style="margin-top: -8px;margin-left: -2px;">
                                    <img style="width: 50px; height: 50px;" class="nyne-icon" src="@/assets/media/Copy_light.svg" alt="Validierte Punktwolke anzeigen" />
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <button class="btn button is-primary dropdown-item m-0" v-for="item in 5" :key="item" :class="{ 'selected': currentTolerance == `0${item}` }"
                                    @click="setCurrentTolerance(`${item}`)">
                                    <div class="icon-wrapper">
                                        <img class="nyne-icon" src="@/assets/media/Copy_light.svg" alt="Validierte Punktwolke anzeigen" />
                                    </div>
                                    <span class="column text p-0 pl-4">{{ item }} cm</span>
                                </button>
                                <button v-for="file in project.projectFiles" :key="file.id"
                                    @click="togglePointCloud(`model_${file.id}`)" class="btn button is-primary dropdown-item m-0">
                                    <div class="icon-wrapper">
                                        <img src="@/assets/media/View_hide_light.svg" alt="Hide Validated Pointcloud" />
                                    </div>
                                    <span class="column text p-0 pl-4">{{ $t('ProjectBim.HideValidatedPointcloud') }}</span>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <button class="btn button menu-item is-primary" @click="toggleSectionPlane">
                            <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                <img style="width: 30px; height: 30px;" class="nyne-icon" src="@/assets/media/Knife_light.svg" alt="Section Plane" />
                            </div>
                        </button>
                    </li>

                </ul>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { Viewer, XKTLoaderPlugin, NavCubePlugin, DistanceMeasurementsPlugin, DistanceMeasurementsMouseControl, PointerLens, AngleMeasurementsPlugin, AngleMeasurementsMouseControl, SectionPlanesPlugin, BCFViewpointsPlugin, math } from '@xeokit/xeokit-sdk';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

import { ProjectView } from '@/models/project.model';
import { useDownloadHelper } from '@/utils/helper';
import { formatDate } from '@/utils/formatBytes';
import axios from 'axios';

const store = useStore();
const route = useRoute();
const { t } = useI18n();
const { downloadPointcloud, downloadIFC, downloadDWG, downloadPLN, downloadRVT, downloadC2ME57, downloadLAZ, downloadXKTIFC, downloadXKTLAZ, downloadBCF } = useDownloadHelper(store);

// State to track the distance measurement plugin
let distanceMeasurementPlugin: DistanceMeasurementsPlugin | null = null;
let distanceMeasurementControl: DistanceMeasurementsMouseControl | null = null;
let sectionPlanesPlugin: SectionPlanesPlugin | null = null;
let bcfViewpointsPlugin: BCFViewpointsPlugin | null = null;
let navCubePlugin: NavCubePlugin | null = null;
const isMeasuring = ref(false); // Track if measurement mode is active
const isMeasuringAngle = ref(false); // Track angle measurement mode
const is3D = ref(true); // Track whether the view is in 3D or 2D

const project: any = reactive({});
const loadedModels = ref<Record<string, any>>({});
const currentTolerance = ref<string>('01');
const squareMeters = ref<string>('');
const userDateFormat = computed(() => store.state.auth.userData?.dateFormat ?? 'dd.MM.yyyy');

let viewer: Viewer | null = null; // Track the Viewer instance
let pointerLens: PointerLens | null = null;
let angleMeasurementPlugin: AngleMeasurementsPlugin | null = null;
let angleMeasurementControl: AngleMeasurementsMouseControl | null = null;
let sectionPlane: any = null;


store.commit('app/setLayout', 'full');

// get projectId from the route
const projectId = route.params.id;
const isAdmin = computed(() => store.state.auth.isAdmin)
const bcfJsonData = ref<any>([]);

const setCurrentTolerance = (tolerance: string) => {
    currentTolerance.value = '0' + tolerance;
    cleanupViewer();
    setupViewer(project);
};

const getDownloadLinkResult = () => store.dispatch('project/getDownloadLink', { id: projectId, type: 'xkt_ifc' });
const getDownloadLinkInput = (fileId: string, suffix: string) => store.dispatch('project/getDownloadLink', { id: projectId, type: 'xkt_laz', fileId: fileId, suffix: suffix });
const getBcfLink = () => store.dispatch('project/getDownloadLink', { id: projectId, type: 'bcfjson', suffix: currentTolerance.value });
const getCsvLink = () => store.dispatch('project/getDownloadLink', { id: projectId, type: 'csv' });

const togglePointCloud = (modelId: string) => {
    if (viewer && loadedModels.value[modelId]) {
        const model = loadedModels.value[modelId];
        if (!model) return;

        viewer.scene.models[modelId].visible = !viewer.scene.models[modelId].visible;
    }
};

const toggleSectionPlane = () => {
    if (!sectionPlanesPlugin) return;
    // If we haven't stored the plane yet, create one now:
    if (!sectionPlane) {
        sectionPlane = sectionPlanesPlugin.createSectionPlane({
            pos: viewer!.scene.center,
            dir: math.normalizeVec3([1.0, 0.01, 1]),
            active: true,
        });
    } else {
        // Toggle active & visible
        sectionPlane.active = !sectionPlane.active;
        if (sectionPlane.active) {
            sectionPlanesPlugin.showControl(sectionPlane.id);
        } else {
            sectionPlanesPlugin.hideControl();
        }
    }
};

const setViewpoint = (index: number) => {
    bcfViewpointsPlugin?.setViewpoint(bcfJsonData.value[index].viewpoint);
}

const fetchBcfJson = async () => {
    try {
        const link = await getBcfLink();
        const response = await axios.get(link, { responseType: 'json' });

        // Ensure response is valid JSON
        if (!response || typeof response.data !== 'object') {
            bcfJsonData.value = [];
            return;
        }

        // Assign the response data
        bcfJsonData.value = response.data;
    } catch (error: any) {
        console.error("Error while loading BCF JSON:", error.message);
        bcfJsonData.value = []; // Set a fallback value
    }
};

const setupViewer = async (project: ProjectView) => {
    viewer = new Viewer({
        canvasId: 'xeokit_canvas',
        transparent: true,
        dtxEnabled: true,
    });

    viewer.camera.eye = [-3.933, 2.855, 27.018];
    viewer.camera.look = [4.400, 3.724, 8.899];
    viewer.camera.up = [-0.018, 0.999, 0.039];

    const xktLoader = new XKTLoaderPlugin(viewer);
    // Instantiate the SectionPlanesPlugin
    sectionPlanesPlugin = new SectionPlanesPlugin(viewer, {
        overviewCanvasId: "mySectionPlanesOverviewCanvas",
        overviewVisible: true
    });


    bcfViewpointsPlugin = new BCFViewpointsPlugin(viewer);

    const modelOutput = xktLoader.load({
        id: 'modelOutput',
        src: await getDownloadLinkResult(),
        saoEnabled: true,
        edges: true,
        //   dtxEnabled: true,
    });

    modelOutput.on("loaded", async () => {
        await fetchBcfJson();
    })

    loadedModels.value = {};
    const modelIds = [modelOutput.id];

    // Iterate over project files and load each point cloud dynamically
    if (project.projectFiles.length > 0) {
        for (const file of project.projectFiles) {
            const modelId = `model_${file.id}`; // Unique ID for each model

            const model = xktLoader.load({
                id: modelId,
                src: await getDownloadLinkInput(file.id, currentTolerance.value),
                saoEnabled: true,
                edges: true,
            });

            loadedModels.value[modelId] = model;
            modelIds.push(modelId);
        }

    }

    const aabb = viewer.scene.getAABB(modelIds);

    // Calculate the center of the model
    const center = [
        (aabb[0] + aabb[3]) / 2,
        (aabb[1] + aabb[4]) / 2,
        (aabb[2] + aabb[5]) / 2,
    ];

    // Set the camera to look at the center of the model
    viewer.camera.eye = [center[0] + 30, center[1] + 20, center[2] + 30]; // Adjust based on your desired distance
    viewer.camera.look = center; // Look at the center of the model
    viewer.camera.up = [0, 1, 0]; // Ensure the up vector is vertical

    navCubePlugin = new NavCubePlugin(viewer, {
        canvasId: "navcube", // The container for the nav cube
        visible: true, // Show the cube
        cameraFly: true, // Smooth camera transition when clicking cube sides
        // size: [150, 150], // Size of the cube
    });

    // Initialize the DistanceMeasurementsPlugin for distance measurement
    distanceMeasurementPlugin = new DistanceMeasurementsPlugin(viewer);

    distanceMeasurementPlugin.on('measurementStart', (distanceMeasurement) => {
        console.log('Measurement started', distanceMeasurement);
    });

    distanceMeasurementPlugin.on('measurementEnd', (distanceMeasurement) => {
        console.log('Measurement ended');
        console.log(`Measured distance: ${distanceMeasurement} meters`);
    });

    // Initialize the AngleMeasurementsPlugin
    angleMeasurementPlugin = new AngleMeasurementsPlugin(viewer);
    angleMeasurementControl = new AngleMeasurementsMouseControl(angleMeasurementPlugin, {
        snapping: true,
    });

    pointerLens = new PointerLens(viewer, {
        zoomFactor: 5, // Zoom level (higher is more zoomed-in)
        containerId: 'minimap', // Attach to the minimap container,
        active: true,
        lensPosMarginLeft: 10,
        lensPosMarginTop: 100,
    });

    // Initialize mouse control for distance measurements
    distanceMeasurementControl = new DistanceMeasurementsMouseControl(distanceMeasurementPlugin, {
        pointerLens: pointerLens, // To magnify the mouse pointer location
        snapping: true, // Enable snapping to edges and vertices
    });


    // Activate the measurement control
    // distanceMeasurementControl.activate();
};

// Function to activate distance measurement
const activateDistanceMeasurement = () => {
    if (distanceMeasurementControl && !isMeasuring.value) {
        // Enable the plugin and set measurement mode
        // distanceMeasurementControl.reset();
        if (angleMeasurementControl && isMeasuringAngle.value) {
            angleMeasurementControl.deactivate();
            isMeasuringAngle.value = false;
        }
        distanceMeasurementControl.activate();
        isMeasuring.value = true;
        console.log("Measurement mode activated. Click to select points.");
    } else if (distanceMeasurementControl && isMeasuring.value) {
        distanceMeasurementControl.deactivate();
        isMeasuring.value = false;
        console.log("Measurement mode deactivated.");
    }
};

const activateAngleMeasurement = () => {
    if (angleMeasurementControl && !isMeasuringAngle.value) {
        // angleMeasurementControl.reset();
        angleMeasurementControl.activate();
        if (distanceMeasurementControl && isMeasuring.value) {
            distanceMeasurementControl.deactivate();
            isMeasuring.value = false;
        }
        isMeasuringAngle.value = true;
        console.log("Angle measurement mode activated.");
    } else if (angleMeasurementControl && isMeasuringAngle.value) {
        angleMeasurementControl.deactivate();
        isMeasuringAngle.value = false;
        console.log("Angle measurement mode deactivated.");
    }
};

// Function to stop and reset measurements
const resetMeasurements = () => {
    if (distanceMeasurementPlugin) {
        distanceMeasurementPlugin.clear(); // Clear all measurements
        isMeasuring.value = false; // Set measuring state to false
    }
    if (angleMeasurementPlugin) {
        angleMeasurementPlugin.clear();
        isMeasuringAngle.value = false;
    }
};

// Function to clean up the viewer when the component is unmounted
const cleanupViewer = () => {
    if (distanceMeasurementControl) {
        distanceMeasurementControl.deactivate();
        distanceMeasurementControl.destroy();
        distanceMeasurementControl = null;
    }
    if (distanceMeasurementPlugin) {
        distanceMeasurementPlugin.clear();
        distanceMeasurementPlugin.destroy();
        distanceMeasurementPlugin = null;
    }
    if (angleMeasurementControl) {
        angleMeasurementControl.deactivate();
        angleMeasurementControl.destroy();
        angleMeasurementControl = null;
    }
    if (angleMeasurementPlugin) {
        angleMeasurementPlugin.clear();
        angleMeasurementPlugin.destroy();
        angleMeasurementPlugin = null;
    }
    if (navCubePlugin) {
        navCubePlugin.destroy();
        navCubePlugin = null;
    }
    if (sectionPlanesPlugin) {
        sectionPlane = null;
        sectionPlanesPlugin.clear();
        sectionPlanesPlugin.destroy();
        sectionPlanesPlugin = null;
    }
    if (bcfViewpointsPlugin) {
        bcfViewpointsPlugin.destroy();
        bcfViewpointsPlugin = null;
    }
    if (viewer) {
        viewer.destroy(); // Destroy the viewer and release resources
        viewer = null;
        console.log("Viewer destroyed and resources released.");
    }
};

const fitView = () => {
    if (viewer) {
        // Get all object IDs in the scene
        const objectIDs = Object.keys(viewer.scene.objects); // Retrieve all object IDs

        // Fit the camera to the bounding box of the entire scene
        viewer.cameraFlight.flyTo({
            aabb: viewer.scene.getAABB(objectIDs) // Pass all object IDs to get the bounding box
        });
    }
};

const toggle2D3D = () => {
    if (!viewer) {
        return;
    }
    const objectIDs = Object.keys(viewer.scene.objects); // Retrieve all object IDs
    const aabb = viewer.scene.getAABB(objectIDs); // Get the AABB of the entire scene

    // Calculate the center of the AABB
    const center = [
        (aabb[0] + aabb[3]) / 2,
        (aabb[1] + aabb[4]) / 2,
        (aabb[2] + aabb[5]) / 2
    ];

    // Calculate the size of the model based on AABB dimensions
    const modelWidth = aabb[3] - aabb[0];
    const modelHeight = aabb[4] - aabb[1];
    const modelDepth = aabb[5] - aabb[2];

    // Determine the appropriate distance for 2D and 3D views
    const distanceFactor = 2; // Factor to adjust the camera distance (increase for farther view)

    if (is3D.value) {
        // Switch to 2D (top-down orthographic view)
        viewer.cameraFlight.flyTo({
            eye: [center[0], center[1] + modelHeight * distanceFactor, center[2]], // Above the model
            look: center, // Look at the center of the model
            up: [0, 0, -1], // Align the camera to a top-down view
            projection: "ortho" // Use orthographic projection for 2D
        });
        console.log("Switched to 2D (top-down orthographic) view");
    } else {
        // Switch back to 3D (perspective view)
        viewer.cameraFlight.flyTo({
            eye: [center[0] + modelWidth * distanceFactor, center[1] + modelHeight * 0.5, center[2] + modelDepth * distanceFactor], // Set 3D view distance
            look: center, // Look at the center of the model
            up: [0, 1, 0], // Keep the up vector vertical
            projection: "perspective" // Use perspective projection for 3D
        });
        console.log("Switched to 3D (perspective) view");
    }

    is3D.value = !is3D.value; // Toggle the state

};

const fetchSquareMeters = async () => {
    try {
        const csvLink = await getCsvLink();
        const response = await axios.get(csvLink, { responseType: 'text' });

        if (!response.data || response.data.trim().toLowerCase().startsWith("<!doctype html>")) {
            squareMeters.value = ""; // Set default fallback value
            return;
        }

        // Extract and format value
        squareMeters.value = response.data.trim().replace(".", ",") + " M2";
    } catch (error: any) {
        console.error("Error while loading squareMeters:", error.message);
        squareMeters.value = ""; // Set default fallback value
    }
};

onMounted(async () => {
    await store.dispatch('project/getProject', projectId);
    const p = store.getters['project/projectById'](projectId);

    if ((isAdmin.value === true) || p.status === 'FILE_PROCESSING_FINISHED') {
        Object.assign(project, p);
        await setupViewer(p);
        
        fetchSquareMeters();
        
    } else {
        store.commit('app/showToast', {
            message: t('ProjectBim.ProjectProcessing'),
            type: 'warning',
        });
    }
});
onBeforeUnmount(() => {
    if (distanceMeasurementControl) {
        distanceMeasurementControl.deactivate();
    }
    cleanupViewer();
});
</script>

<style scoped lang="scss">
.xeokit-viewer-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    /* Ensures no unwanted scroll */
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    user-select: none;
}

.xeokit-viewer {
    width: 100%;
    height: 100%;
    background: lightblue;
    background-image: linear-gradient(lightblue, white);
    position: absolute;
}

.nav-cube {
    position: absolute;
    bottom: -20px;
    left: calc(25vw - 225px);
    width: 250px;
    height: 250px;
    z-index: 99999999;
}

.minimap {
    position: absolute;
    top: 110px;
    left: 10px;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px solid #ccc;
    z-index: 9999;
    backdrop-filter: blur(10px);
}

.ruler-buttons {

    position: absolute;
    bottom: 10px;
    left: calc(50% - 230px);
    z-index: 9999;
    padding: 10px;
    font-size: 1.2rem;

    border-radius: 100px;
    border: 1.4px solid rgba(255, 255, 255, 0.40);
    background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
    background-blend-mode: luminosity;
    /* Blur */
    backdrop-filter: blur(50px);

    .button {
        background: transparent;
        color: white;
        border: none;
        cursor: pointer;
        margin: 6px;

        &.is-selected {
            background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
            background-blend-mode: luminosity;
            border-radius: 34px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.40);
        }
    }
}


section {
    height: 100vh;
    /* Ensure the viewer takes up the full height */
}

canvas {
    width: 100%;
    height: 100%;
}

.ruler-buttons {
    // min-width: 340px;
    z-index: 999999;
    transition: all 0.3s ease-in-out;

    .menu-list {
        &>li {
            display: inline-block;

            &>a,
            &>button {
                border-radius: 34px;
                height: 52px;
                width: 52px;
                display: inline-block;
                // background-color: white;
            }

            .dropdown-menu {
                border-radius: 34px;
                background-color: var(--Windows-Glass, rgba(128, 128, 128, 0.40));

                background-blend-mode: luminosity;
                border: 1.4px solid rgba(255, 255, 255, 0.40);
                backdrop-filter: blur(50px);

                .dropdown-content {
                    border-radius: 22px;
                    background-color: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
                    background-blend-mode: luminosity;
                    box-shadow: none;
                    padding: 0;
                    backdrop-filter: blur(50px);
                    margin: 5px;
                }
            }

            .dropdown-item {
                // padding-bottom: 10px;
                // padding-top: 10px;
                padding-left: 20px;
                border-radius: 22px;

                &>* {
                    display: inline-block;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.40);
                }

                .text {
                    font-size: 1.2rem;
                    vertical-align: text-bottom;
                }

                .icon-wrapper {
                    height: 30px;
                    min-height: 30px;
                }
            }
        }

        border-radius: 100px;

        .icon-wrapper {
            height: 52px;
            width: 30px;
            margin-top: 4px;

            .nyne-icon {
                font-size: 1.2rem;
            }

        }

        .dropdown-trigger {
            .button.menu-item {
                width: 52px;
                height: 52px;
            }
        }

        [class^="nyne-icon-"]:before,
        [class*=" nyne-icon-"]:before {
            // height: 52px;
            // width: 38px;
            margin: 0;
            // margin-left: -12px;
        }

        .menu-item.disabled {
            color: #eee !important;
            background-color: transparent;
            cursor: not-allowed;

            &:hover {
                color: #eee !important;
                background-color: transparent;
            }
        }
    }

    & .menu .text {
        display: none;
    }

    .svg-inline--fa {
        height: 1.5em;
    }

    &.is-active {
        .menu-item {
            border-radius: 45px;
        }
    }

    // &.is-active {
    .router-link-exact-active {
        background-color: rgba(255, 255, 255, 0.40);
        color: black;
        border-radius: 34px;

        .menu-item {
            border-radius: 34px;
        }
    }

    &:hover {
        .menu-item {
            border-radius: 34px;
        }

        transition: all 0.3s ease-in-out;
        // left: 0px;

        // & .menu .text {
        // opacity: 1;
        // width: auto;
        // height: auto;
        // }
    }

    // & .menu .text {
    //   transition: all 0.3s ease-in-out;
    // opacity: 0.01;
    // width: 1px;
    // height: 1px;
    // }
}

.sidebar-right {
    position: absolute;
    right: 20px;
    top: 60px;
    
    border-radius: 32px;
    border: 1.4px solid rgba(255, 255, 255, 0.20);
    background: var(--Windows-Glass, rgba(196, 196, 196, 0.30));
    background-blend-mode: luminosity;
    /* Blur */
    backdrop-filter: blur(16px);

    height: calc(100vh - 80px);
    width: 300px;

    .issues {
        height: calc(100vh - 235px);
        overflow-y: scroll;
    }

    .summary {
        text-align: center;
    }
}
</style>